<template>
  <div>
    <el-row style="margin-top: 20px;">
      <el-col :span="14" :offset="4">
<!--        {{applyForm}}-->

        <el-form :model="applyForm" :rules="rules" ref="applyForm" label-width="15%" class="applyForm">
          <header class="form_header">请填写申报信息</header>
          <br/>

          <el-form-item label="申报类型" required>
            <el-select v-model="applyForm.type" @change="selectType">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属类别" required>
<!--            {{detailOptions}}-->
            <el-select v-model="applyForm.detail" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in detailOptions[applyForm.type]"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="活动内容" prop="content">
            <el-input type="textarea" maxlength="100"  show-word-limit v-model="applyForm.content"></el-input>

          </el-form-item>

          <el-form-item label="申报学时/时长" required>

            <el-input-number v-model="applyForm.score" :min="1" :max="70" label="描述文字"></el-input-number>
            <el-button size="small" icon="el-icon-info" type="info" @click="standardDialogVisible=true" style="margin-left: 10px">折算标准</el-button>
          </el-form-item>

          <el-form-item label="活动时间" required>

            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="date"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00']">
            </el-date-picker>




          </el-form-item>


          <el-form-item  label="选择律师" >
            <el-button size="small" icon="el-icon-s-custom" type="success" @click="lawyerDialogVisible=true">点击选择律师</el-button>
            <div>
<!--              {{selectedLawyer}}-->
              <span v-show="selectedLawyer.length != 0">已选择： </span>
              <el-tag v-for="item in selectedLawyer " effect="plain" size="small"
                      style="margin-right:6px">{{ item.name }}
              </el-tag>

            </div>


          </el-form-item>

          <el-form-item label="图片证明" required>
            <el-upload
              :on-change="handleChange"
              class="upload-demo"
              action="la"
              ref="upload"
              :multiple=false
              :limit=9
              accept=".jpg, .png"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :on-exceed="handleExceed"
              :before-remove="beforeRemove"
              :auto-upload="false">
              <el-button size="small" slot="trigger"  type="success" icon="el-icon-picture-outline">点击上传图片</el-button>
              <div slot="tip" class="el-upload__tip">最多可上传9张图片，只能上传jpg文件，且每张图片不超过5MB</div>
            </el-upload>
          </el-form-item>
          <br>

          <el-form-item>
            <div style="text-align:center">
              <el-button size="medium" type="primary" style="width: 300px" @click="submitForm('applyForm')">提交</el-button>
              <!--            <el-button @click="resetForm('applyForm')">重置</el-button>-->
            </div>
          </el-form-item>


        </el-form>
      </el-col>
    </el-row>


    <!--    <div v-model="applyForm">{{applyForm}}</div>-->

    <el-dialog


      :visible.sync="lawyerDialogVisible"
      :show-close="false"
      width="30%"
      class="lawyerDialog"
    >

        <el-row>
          <el-col :span="12">
            <h3 style="margin-top: 0">已选人数：{{selectedLawyer.length}} </h3>
          </el-col>
          <el-col :span="12">

            <el-input
              placeholder="输入姓名可搜索律师"
              v-model="searchLawyerName"
              :value="searchLawyerName"
              @keyup.native="handleSearch"
              size="small"
            >
            </el-input>
          </el-col>


        </el-row>

        <el-table
          :row-class-name="tableRowClassName"
          max-height="400"
          tooltip-effect="dark"
          ref="table"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange">

          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="name"
            label="律师姓名"
            align="center"
            width="120">
          </el-table-column>
          <el-table-column
            prop="practiceno"
            label="律师证号"
            align="center"
            show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="toggleSelection()">取消选择</el-button>
            <el-button size="small" type="primary" @click="handleLawyerConfirm">确定</el-button>
        </span>




    </el-dialog>

<!--    折算标准对话框-->
    <el-dialog
      :fullscreen="true"
      :visible.sync="standardDialogVisible"
      :show-close="true"
      class="standardDialog"
    >
      <div>
        <div>
          <h1>培训学时折算标准</h1>
          <el-table
            :data="trainStandard"
            border
            style="width: 100%">
            <el-table-column
              :resizable="false"
              label="序号"
              type="index"
              align="center"
              width="50">
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="type"
              label="培训类型"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="standard"
              align="center"
              label="学时折算标准">
            </el-table-column>
          </el-table>
        </div>

        <div style="margin-top: 80px">
          <h1>公益法律服务时长折算标准</h1>
          <el-table
            :data="serviceStandard"
            border
            style="width: 100%">
            <el-table-column
              :resizable="false"
              label="序号"
              type="index"
              align="center"
              width="50">
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="type"
              label="公益法律服务类型"
              align="center"
              >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="standard"
              align="center"
              label="时长折算标准">
            </el-table-column>
          </el-table>
        </div>

      </div>
    </el-dialog>


  </div>
</template>
<script>

import { request } from '@/network/network'
import { serviceScore_standard, trainScore_standard } from '@/utils/standard'
import {
  application_type_options,
  application_detailTrain_options,
  application_detailPublic_options,
  application_detailPublic_dict, application_detailTrain_dict,
  application_detailEight_options,
  application_detailEight_dict
} from '@/utils/dict'
import axios from 'axios'

export default {
  data() {
    return {
      serviceStandard: serviceScore_standard,
      trainStandard:trainScore_standard,

      searchIndex: 0,
      searchLawyerName: '',
      //图片
      file: [],
      fileList: [],
      date: [],
      lawyers: [],
      tableData: [],
      lawyerDialogVisible: false,
      standardDialogVisible: false,
      applyForm: {
        content: '',
        type: 0,
        detail: 0,
        score: 2,//申请课时

        did:'',
        // startTime: '',
        // endTime: '',
        lawyers: []
      },
      selectedLawyer: [],
      selectedTemp: [],

      //下拉框选项
      typeOptions: application_type_options,
      detailOptions: [application_detailTrain_options, application_detailPublic_options, application_detailEight_options],
      rules: {
        content: [
          { required: true, message: '请填写活动主要内容', trigger: 'blur' }
        ]
      },
      adminInformation: JSON.parse(sessionStorage.getItem("information"))
    }
  },
  mounted() {

  },
  methods: {
    //搜索后改变当前行样式
    tableRowClassName({row, rowIndex}) {
      if(this.searchIndex === 0) {
        return '';
      }
      else if (rowIndex === this.searchIndex) {
        return 'success-row';
      }
    },
    //搜索
    handleSearch() {
      if (this.searchLawyerName == '') {
        this.searchIndex = 0
        this.$refs.table.bodyWrapper.scrollTop = 0
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].name.indexOf(this.searchLawyerName) > -1) {
            this.$refs.table.bodyWrapper.scrollTop = this.$refs.table.bodyWrapper.scrollTop + 46 * (i - this.searchIndex)
            this.searchIndex = i
            return
          }
        }
      }
    },

    //图片
    handleRemove(file, fileList) {
      this.fileList = fileList
      console.log('handleRemove-----------------')

    },
    handlePreview(file) {
      console.log('handlePreview-----------------')
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`您最多可以上传9张照片`)
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    handleChange(file, fileList) {
      this.fileList = fileList

    },
    //多选table方法
    handleSelectionChange(val) {
      this.selectedLawyer = val

    },
    handleLawyerConfirm() {
      this.applyForm.lawyers = []

      for (let item of this.selectedLawyer) {
        this.applyForm.lawyers.push(item.id)
      }

      this.lawyerDialogVisible = false
      // this.applyForm.

    },

    //选择律师框取消选择
    toggleSelection(rows){
      if (rows) {
        rows.forEach(row => {
          this.$refs.table.toggleRowSelection(row);
        });
      } else {
        this.$refs.table.clearSelection();
      }
      this.applyForm.lawyers = []
      this.selectedLawyer = []
      this.lawyerDialogVisible = false
      this.searchIndex = 0
      this.searchLawyerName = ''
    },
    //类型选择器
    selectType() {
      this.applyForm.detail = 0
      console.log('-----------')
    },
    judeSize(){
      for (const file of this.fileList) {
        console.log(file)
        const isLt2M = file.size / 1024 / 1024 < 5;
        if(!isLt2M){return isLt2M}
      }
      return true
    },


    submitForm(formName) {
      if (this.applyForm.lawyers.length == 0) {
        this.$message({
          message: '请至少选择一位律师！',
          type: 'error',
          duration: 1000
        })
        return false
      } else if (this.fileList.length == 0) {
        this.$message({
          message: '请上传图片证明！',
          type: 'error',
          duration: 1000
        })
        return false
      } else if(!this.judeSize()){
        this.$message({
          message: '不能上传大于5MB的图片,请重新选择图片！',
          type: 'error',
          duration: 1000
        })
        this.fileList = []
        this.$refs.upload.clearFiles();
        return false
      }
      else {

        this.$refs[formName].validate((valid) => {
          if (valid) {
            //时间
            this.$set(this.applyForm, 'startTime', this.date[0])
            this.$set(this.applyForm, 'endTime', this.date[1])
            //将detail转化为文字
            if (this.applyForm.type == 0) {
              this.$set(this.applyForm, 'detail', application_detailTrain_dict[this.applyForm.detail])
            }
            else if(this.applyForm.type == 1) {
              this.$set(this.applyForm, 'detail', application_detailPublic_dict[this.applyForm.detail])
            }
            else {
              this.$set(this.applyForm, 'detail', application_detailEight_dict[this.applyForm.detail])
            }
            this.applyForm.did = this.adminInformation.did

            //处理图
            var data = new FormData()//重点在这里 如果使用 var data = {}; data.inputfile=... 这样的方式不能正常上传
            // formData重复的往一个值添加数据并不会被覆盖掉，可以全部接收到，可以通过formData.getAll('files')来查看所有插入的数据
            for (let i = 0; i < this.fileList.length; i++) {
              data.append('files', this.fileList[i].raw)
            }
            var application = JSON.stringify(this.applyForm)
            data.append('applicationDTO', new Blob([application], { type: 'application/json' }))
            console.log('applyForm===============')
            console.log(this.applyForm)

            let headers = { headers: { 'Content-Type': 'multipart/form-data' } }
            const loading = this.$loading({
              lock: true,
              text: '请稍等',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            // https://api.shuobocloud.cn/batch/batchApply
            axios.post('http://43.139.249.33:8083/batch/batchApply', data, headers, { processData: false })
              .then(res => {
                loading.close();
                if(res.data.code == 500){
                  this.$message({
                    message: '上传失败,'+res.data.msg,
                    type: 'error',
                    duration: 1000
                  })
                }
                else{
                  this.$message({
                    message: '申请成功！',
                    type: 'success',
                    duration: 1000
                  })
                  this.$router.push('myBatchApplyF')
                }
              })
              .catch(err => {
                loading.close();

                this.$message({
                  message: '发生错误，申请失败，请重试！',
                  type: 'error',
                  duration: 1000
                })


              })
            //   .then(function(data) {
            //     console.log(data)
            //     this.$message({
            //       message: '申请成功！',
            //       type: 'success',
            //       duration: 1000
            //     })
            //     this.$router.push('myBatchApplyF')
            //   }, function(err) {
            //     console.log('err------: ')
            //     console.log(err)
            //   })
            //
            // this.$message({
            //   message: '申请成功！',
            //   type: 'success',
            //   duration: 1000
            // })
            // this.$router.push('myBatchApplyF')


          } else {
            this.$message({
              message: '请完善信息！',
              type: 'error',
              duration: 1000
            })
            return false
          }
        })
      }
    },

    getLawyers() {
      return request({
        url: '/batch/lawyers',
        method: 'get',
        params: {
          did: this.adminInformation.did
        }
      }).then(res => {
        this.tableData = res.data
      })
    }
  },
  created() {
    this.getLawyers()
  }
}
</script>

<style>
.demo-ruleForm {
  border: 1px solid #eaeefb;
  padding: 10px 10px 0;
  text-align: center;
}

.el-table .success-row {
  background: #f0f9eb;
}
/*.nameList li{*/
/*  float:left*/
/*  */
/*}*/
</style>
